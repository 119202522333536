import getClient from './client'

export default async function fetchYahooAds(
  siteID,
  keyword,
  maxCount,
  type,
  market,
  source,
) {
  if (keyword === ``) return []
  if (siteID === undefined) return []

  const { data } = await getClient().get(`adpartners/${siteID}/fetchYahooAds`, {
    params: {
      s: encodeURIComponent(keyword),
      n: maxCount,
      t: type,
      mkt: market,
      src: source,
    },
  })

  const result = data || []

  return result.map((item, index) => ({
    id: index,
    url: item.url.replace(/\\/g, ``),
    title: item.title.replace(/\\/g, ``),
    description: item.description.replace(/\\/g, ``),
    clickUrl: item.click_url.replace(/\\/g, ``),
    related: item.related,
    taScore: item.trueaudience_score,
    impressionId: item.impression_id,
    appNs: item.appNs,
    k: item.k,
    metadata: item.metadata,
    yahooResponse: item.yahoo_response,
  }))
}
